import * as React from 'react'
import * as styles from './page-title.module.css'

const PageTitle = ({ title, superTitle, subTitle, className }) => {
    return (
        <div className={className || 'text-center'}>
            <p className={styles.superTitle}>{superTitle}</p>
            <h1 className={styles.title}>{title}</h1>
            <h2 className={styles.subTitle}>{subTitle}</h2>
        </div >
    )
}

export default PageTitle