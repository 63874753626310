import * as React from 'react'
import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page-title/page-title'
import ReCAPTCHA from 'react-google-recaptcha';
import { Formik } from 'formik';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import * as styles from '../styles/contact.module.css'


const ContactPage = () => {
    const [showSubmissionSuccess, setShowSubmissionSuccess] = React.useState(false)
    const [showSubmissionError, setShowSubmissionError] = React.useState(false)
    const recaptchaRef = React.useRef()

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }

        if (!values.message) {
            errors.message = 'Required';
        }

        if (!recaptchaRef.current.getValue()) {
            errors.recaptcha = 'Required';
        }

        return errors;
    }
    const onSubmit = async (values, { resetForm }) => {
        setShowSubmissionError(false);

        try {
            await axios({
                url: 'https://formspree.io/f/xjvdered',
                method: 'post',
                data: {
                    email: values.email,
                    name: values.name,
                    message: values.message,
                    'g-recaptcha-response': recaptchaRef.current.getValue()
                },
                headers: {
                    'Accept': 'application/json'
                }
            })
            setShowSubmissionSuccess(true);
        } catch (e) {
            setShowSubmissionError(e.response.data.error);
        } finally {
            resetForm();
            recaptchaRef.current.reset();
        }
    }
    return (
        <Layout>
            <PageTitle
                superTitle='CONTACT US'
                title={<><span>Get in touch</span> with us</>}
                subTitle={<>If you have any questions about our products or would like to connect with us, leave us a message using the form below.</>}
            />
            <div className='m-auto' style={{ maxWidth: '600px', paddingTop: '50px', paddingBottom: '50px' }}>
                <Formik
                    initialValues={{ email: '', name: '', message: '' }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit} className={Object.keys(errors).length ? 'is-invalid' : ''}>
                            <div className="mb-2" style={{ minHeight: '72px' }}>
                                <input
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    className="form-control"
                                    placeholder="Your Name"
                                />
                                <div className="invalid-feedback">
                                    {errors.name && touched.name && errors.name}
                                </div>
                            </div>
                            <div className="mb-2" style={{ minHeight: '72px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className="form-control"
                                    placeholder="Email Address"
                                />
                                <div className="invalid-feedback">
                                    {errors.email && touched.email && errors.email}
                                </div>
                            </div>
                            <div className="mb-2" style={{ minHeight: '207px' }}>
                                <textarea name="message" rows={5} onChange={handleChange} onBlur={handleBlur} value={values.message} className="form-control" placeholder="Message" />
                                <div className="invalid-feedback">
                                    {errors.message && touched.message && errors.message}
                                </div>
                            </div>
                            <div style={{ minHeight: '103px' }}>
                                <ReCAPTCHA
                                    sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                                    ref={recaptchaRef}
                                />
                                <div className="invalid-feedback text-start">
                                    {errors.recaptcha}
                                </div>
                            </div>
                            <div className={`${styles.submitError} ${showSubmissionError ? 'visible' : 'invisible'}`}>
                                {showSubmissionError}
                            </div>
                            <button type="submit" className={"btn btn-primary mt-2 mb-3 border-0"} disabled={isSubmitting}>
                                Submit
                                <FontAwesomeIcon icon={faPaperPlane} size="lg" className='mx-2' />
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
            <div className={(showSubmissionSuccess ? '' : ' d-none')}>
                <p className='text-center mt-5'>Thank you for your message! We will get back to you very soon.</p>
            </div>
        </Layout>
    )
}


export const Head = ({ location }) => <Seo title="Contact us" locationPath={location.pathname} />

export default ContactPage
